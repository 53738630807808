import React, { useState } from 'react';
import useSWR from 'swr';
import { Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb } from 'antd';
import htmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';

// Icons
import { IoIosSearch } from 'react-icons/io';

// Services
import api from '../../services/api';

// Styles
import { Container, Search, Side, Content, Item } from './styles';

// Components
import Header from '../../components/Header';
import Orcamento from '../../components/Orcamento';
import Parceiros from '../../components/Parceiros';
import Footer from '../../components/Footer';

export default function Busca({ match: { params } }) {
  console.log(params);
  // State
  const [search, setSearch] = useState('');

  // History
  const history = useHistory();

  // Requests
  const { data } = useSWR(`/wp/v2/busca/${params.search}`, url => api.get(url));

  function handleSearch(e) {
    e.preventDefault();
    history.push(`/busca/${search}`);
  }

  return (
    <Scrollbars autoHide universal>
      <Header />

      {!data && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
            paddingBottom: 60,
          }}
        >
          <ReactLoading type="spin" color="#229536" height={60} width={60} />
        </div>
      )}

      {!!data && (
        <Container className="container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item>Busca</Breadcrumb.Item>
          </Breadcrumb>
          <Search>
            <Side>
              <div className="side--search">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    placeholder="PESQUISAR"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                  />
                  <IoIosSearch size={25} color="#000" onClick={handleSearch} />
                </form>
              </div>
              <h3>Categorias</h3>
              <div className="side--categorias">
                {data?.data.categorias_de_produtos.map(categoria => (
                  <Link key={categoria.slug} to={`/produtos/${categoria.slug}`}>
                    {categoria.name}
                  </Link>
                ))}
              </div>
            </Side>
            <Content>
              <h2>
                Resultado de pesquisa para <span>“{params.search}”</span>
              </h2>
              <div>
                {data?.data.resultado.map(item => (
                  <Link to={`/produto/${item.slug}`}>
                    <Item>
                      <div className="square">
                        <img
                          src={
                            item.imagem_nova ? item.imagem_nova : item.imagem
                          }
                          alt={item.titulo}
                        />
                        <div className="border one">
                          <div />
                        </div>
                        <div className="border two">
                          <div />
                        </div>
                      </div>
                      <h3>{htmlParser(item.titulo)}</h3>
                    </Item>
                  </Link>
                ))}
              </div>
              {data?.data.resultado.length < 1 && (
                <p>Nenhum resultado encontrado para a sua pesquisa.</p>
              )}
            </Content>
          </Search>
        </Container>
      )}
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
