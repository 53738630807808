import React, {useState} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb, notification } from 'antd';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

// Services
import ademail from '../../services/ademail';

// Styles
import { Container, Content, Basesolida, Mapa } from './styles';

// Components
import Header from '../../components/Header';
import Atendimento from '../../components/Atendimento';
import Orcamento from '../../components/Orcamento';
import Parceiros from '../../components/Parceiros';
import Footer from '../../components/Footer';

export default function Contato() {
  const [disabledButton, setDisabledButton] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required('Campo nome é obrigatório'),
    email: Yup.string()
      .email('Insira um email válido')
      .required('Campo email é obrigatório'),
    subject: Yup.string().required('Campo assunto é obrigatório'),
    message: Yup.string().required('Campo mensagem é obrigatório'),
  });

  async function handleSubmit(data, { resetForm }) {
    const body = `
    <p>Nome: ${data.name}</p>
    <p>Email: ${data.email}</p>
    <p>Assunto: ${data.subject}</p>
    <p>Mensagem: ${data.message}</p>
  `;

    const email = {
      to: 'basesolida@basesolida.com.br',
      from: data.email,
      as: data.name,
      subject: `${data.subject} - Base Sólida`,
      message: body,
    };

    try {
      setDisabledButton(true);
      await ademail.post('/email', email);
      resetForm();
      setDisabledButton(false);
      notification.success({
        message: 'Agradecemos seu contato!!',
        placement: 'bottomRight',
      });
    } catch (err) {
      setDisabledButton(false);
      notification.error({
        message: 'Oops, algo deu errado!!',
        description: 'Verifique os campos e tente novamente.',
        placement: 'bottomRight',
      });
    }
  }

  return (
    <Scrollbars autoHide universal>
      <Header />
      <Container className="container">
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item>Contato</Breadcrumb.Item>
        </Breadcrumb>
        <Content>
          <div>
            <h2>Contato</h2>
            <p>
              Preencha o formulário abaixo que lhe responderemos o mais breve
              possível. Você também pode nos ligar a qualquer hora de segunda à
              sexta das 8h30 às 18h e sábado das 9h às 12h.
            </p>
            <Form onSubmit={handleSubmit} schema={schema}>
              <div>
                <div className="input--group">
                  <Input type="text" label="Nome" name="name" />
                </div>
                <div className="input--group">
                  <Input type="text" label="Email" name="email" />
                </div>
              </div>
              <Input type="text" label="Assunto" name="subject" />
              <Input
                type="text"
                label="Mensagem"
                name="message"
                multiline
                rows={5}
              />
              <div className='submit-btn'>
                <div className={disabledButton ? 'disabled' : ''} />
                <button type="submit" disabled={disabledButton}>Enviar</button>
              </div>

            </Form>
          </div>
          <img
            src={require('../../assets/images/contato-banner.jpg')}
            alt="Contato"
          />
        </Content>
      </Container>
      <Atendimento />
      <br />
      <br />
      <Mapa className="container">
        <iframe
          title="mapa"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.1853797353083!2d-49.19611968533477!3d-25.432065139201658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcef84e002604b%3A0x59cd6546b03e0aed!2sRod.%20Dep.%20Jo%C3%A3o%20Lepoldo%20Jacomel%2C%2012676%20-%20Emiliano%20Perneta%2C%20Pinhais%20-%20PR%2C%2083324-410!5e0!3m2!1spt-BR!2sbr!4v1569850031268!5m2!1spt-BR!2sbr"
          frameBorder="0"
          style={{ width: '100%', height: '450px', border: 0 }}
          allowFullScreen
        />
      </Mapa>
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
