import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb } from 'antd';

// Styles
import { Container, Servicos, Preservacao, Empresa, Mapa } from './styles';

// Components
import Header from '../../components/Header';
import Atendimento from '../../components/Atendimento';
import Orcamento from '../../components/Orcamento';
import Parceiros from '../../components/Parceiros';
import Footer from '../../components/Footer';

export default function Sobre() {
  return (
    <Scrollbars autoHide universal>
      <Header />
      <Container className="container">
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item>A empresa</Breadcrumb.Item>
        </Breadcrumb>
        <h2>
          A Madeireira <span>Base Sólida</span> do Brasil Ltda está estabelecida
          desde 2001 em Pinhais, região metropolitana de Curitiba – PR – Brasil
        </h2>
        <p>
          Somos <span>apaixonados por madeira</span> e por produtos que deixam
          sua casa mais
          <span> bonita e aconchegante</span>! Possui
          <span> área de 7000 m/2</span> de estoque para atender os mais
          variados pedidos em todo o Brasil. Nossos produtos provém somente de{' '}
          <span>Florestas Manejadas</span> com garantia de procedência e
          qualidade.
        </p>
        <p>
          Trabalhamos com o <span>melhor da madeira nativa brasileira</span>,
          atendendo tanto o mercado nacional como o internacional, com clientes
          em mais de 20 países como :{' '}
          <span>
            Estados Unidos, Itália, França, Espanha, Africa do Sul, China,
            Israel
          </span>{' '}
          dentre outros.
        </p>
        <Servicos>
          <div className="servicos--top">
            <h4>
              A Madeireira Base Sólida também pode oferecer uma grande variedade
              de produtos acabados tais como:
            </h4>
          </div>
          <div className="servicos--bottom">
            <ul>
              <li>Viga para Telhados</li>
              <li>Forro de Madeira</li>
              <li>Assoalhos Maciços</li>
              <li>Deck de Madeira</li>
              <li>Pergolado de Madeira</li>
              <li>Madeira para Marcenaria</li>
            </ul>
          </div>
        </Servicos>
        <Preservacao>
          <img
            src={require('../../assets/images/preservacao-icon.svg')}
            alt="Preservação"
          />
          <div>
            <h4>
              Atualmente seus principais produtos de exportação são o piso
              maciço envernizado (assoalho) e o deck com clientes nos EUA,
              Europa e Ásia.
            </h4>
            <p>
              Seguindo as políticas de conservação ambiental do governo
              Brasileiro e buscando a preservação de nossas florestas a matéria
              prima utilizada provém apenas de áreas com projetos aprovados de
              Manejo Sustentável.
            </p>
          </div>
        </Preservacao>
        <Empresa>
          <img
            src={require('../../assets/images/empresa-banner.jpg')}
            alt="A empresa"
          />
          <div>
            <div>
              <img
                src={require('../../assets/images/location-icon.svg')}
                alt="Location"
              />
              <p>
                Madeireira em <span>Pinhais</span>, estamos localizado na{' '}
                <span>Rod. João Leopoldo Jacomel, 12676</span>.
              </p>
            </div>
            <div>
              <img
                src={require('../../assets/images/like-icon.svg')}
                alt="Like"
              />
              <p>
                Todos os nossos produtos são de excelente procedência.{' '}
                <span>Madeira maciça</span> em Curitiba é na Base Sólida –
                Madeireira em Pinhais.
              </p>
            </div>
            <div>
              <img
                src={require('../../assets/images/ship-icon.svg')}
                alt="Entrega"
              />
              <p>
                Também efetuamos entrega de Madeiras em{' '}
                <span>Curitiba e região</span>. Entre em contato conosco e
                consulte as regiões atendidas pela{' '}
                <span>Base Sólida Madeireira em Pinhais</span>.
              </p>
            </div>
            <div>
              <img
                src={require('../../assets/images/phone-icon.svg')}
                alt="Like"
              />
              <p>
                Tem interesse em nossos produtos? Entre em contato, será prazer
                fornecer as{' '}
                <span>melhores soluções em Madeiras em Curitiba e região</span>!
              </p>
            </div>
          </div>
        </Empresa>
        <Mapa>
          <iframe
            title="mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.1853797353083!2d-49.19611968533477!3d-25.432065139201658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcef84e002604b%3A0x59cd6546b03e0aed!2sRod.%20Dep.%20Jo%C3%A3o%20Lepoldo%20Jacomel%2C%2012676%20-%20Emiliano%20Perneta%2C%20Pinhais%20-%20PR%2C%2083324-410!5e0!3m2!1spt-BR!2sbr!4v1569850031268!5m2!1spt-BR!2sbr"
            frameBorder="0"
            style={{ width: '100%', height: '450px', border: 0 }}
            allowFullScreen
          />
        </Mapa>
      </Container>
      <Atendimento />
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
