import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 25px !important;

  .ant-breadcrumb {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.923077px;
      text-transform: uppercase;
      color: #3f2f2f;

      a {
        color: #3f2f2f;

        &:hover {
          color: #229536;
        }
      }
    }
  }
`;

export const Products = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 65px;
  margin-top: 70px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
`;

export const Side = styled.aside`
  @media (max-width: 480px) {
    display: none;
  }

  .side--search {
    form {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 65px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        width: 1px;
        height: 17px;
        background: #000;
      }

      input {
        width: 100%;
        height: 35px;
        font-weight: normal;
        font-size: 15px;
        line-height: 35px;
        color: #000;
        background: transparent;
        border: none;

        &::placeholder {
          font-weight: normal;
          font-size: 15px;
          line-height: 35px;
          color: #000;
        }
      }

      svg {
        cursor: pointer;
      }
    }
  }

  h3 {
    position: relative;
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #3f2f2f;
    margin-top: 60px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 103%;
      transform: translateY(-50%);
      width: 500px;
      height: 50%;
      background: #229536;
    }
  }

  .side--categorias {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    a {
      position: relative;
      font-weight: normal;
      font-size: 15px;
      line-height: 38px;
      text-transform: uppercase;
      color: #000;

      &:hover:after {
        bottom: 5px;
        background: #000;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 30px;
        height: 1px;
        background: transparent;
        transition: ease 0.3s;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Product = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  min-height: 250px;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
    grid-template-columns: 1fr;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;

    @media (max-width: 480px) {
      margin: 0;
    }

    h3 {
      font-weight: 900;
      font-size: 35px;
      line-height: 41px;
      color: #000;
    }

    button {
      width: 300px;
      height: 85px;
      font-weight: 900;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #229536;
      background: transparent;
      border: 3px solid #229534;
      cursor: pointer;
      transition: all ease-in-out 0.4s;

      &:hover {
        color: #fff;
        background: #229534;
      }
    }
  }

  img {
    max-width: 500px;
    max-height: 500px;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media (max-width: 480px) {
      width: 100%;
      min-height: unset;
      margin-bottom: 20px;
    }
  }
`;

export const Release = styled.div`
  margin-top: 50px;

  .button--group {
    position: relative;
    width: max-content;
    height: 95px;
    display: flex;
    align-items: center;

    @media (max-width: 480px) {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8px;
      background: #3f2f2f;
    }

    .active {
      color: #fff;
      background: #3f2f2f;
    }

    button {
      height: 100%;
      font-weight: 900;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #000;
      background: transparent;
      border: none;
      padding: 0 50px;
      cursor: pointer;
      transition: all ease-in-out 0.4s;

      @media (max-width: 480px) {
        width: 50%;
        padding: 0;
      }
    }
  }

  .release--content {
    position: relative;
    padding: 45px 0 0 40px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 8px;
      height: 90%;
      background: #3f2f2f;
    }

    &__description {
      * {
        display: block;
        font-size: 15px;
        line-height: 38px;
        color: #000;
      }

      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 38px;
        text-transform: uppercase;
        word-break: break-word;
        color: #000;
        margin: 0;

        &:last-of-type {
          margin-bottom: 85px;
        }
      }

      small {
        font-weight: normal;
        font-size: 12px;
        line-height: 26px;
        text-transform: uppercase;
        color: #000;
        margin-top: 65px;
      }
    }
  }
`;

export const Solicitacao = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (max-width: 480px) {
    padding: 10px;
  }

  h4 {
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #229536;
  }

  > p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000;
  }

  form {
    display: flex;
    flex-direction: column;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-gap: unset;
      }

      > div {
        display: flex;
        flex-direction: column;
      }
    }

    label {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      color: #000;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    input,
    textarea {
      font-size: 15px;
      line-height: 25px;
      color: #000;
      border: none;
      border-bottom: 1px solid #000;
    }

    .submit-btn {
        display: flex;
        justify-content: center;
        position: relative;

        > div {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          z-index: -1;
        }

        .disabled {
          z-index: 1;
        }
      }

    button {
      align-self: center;
      height: 53px;
      font-weight: 900;
      font-size: 20px;
      text-transform: uppercase;
      color: #229534;
      background: transparent;
      border: 3px solid #229534;
      padding: 0 40px;
      margin-top: 30px;
      -webkit-transition: all ease-in-out 0.4s;
      transition: all ease-in-out 0.4s;
      cursor: pointer;

      &:hover,
      &:focus {
        background: #229534;
        color: #fff;
      }
    }
  }
`;
