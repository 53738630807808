import React, { useRef } from 'react';
import useSWR from 'swr';
import { Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb } from 'antd';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import htmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';

// Icons
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { TiSocialFacebook, TiSocialTwitter } from 'react-icons/ti';

// Services
import api from '../../../services/api';

// Styles
import { Container, Blog, Side, Item, Content, Post } from './styles';

// Components
import Header from '../../../components/Header';
import Orcamento from '../../../components/Orcamento';
import Parceiros from '../../../components/Parceiros';
import Footer from '../../../components/Footer';

export default function Posts({ match: { params } }) {
  // History
  const history = useHistory();

  // Refs
  let scrollbar = useRef(null);

  // Requests
  const { data } = useSWR(`/wp/v2/posts?slug=${params.slug}&_embed`, url =>
    api.get(url)
  );
  const {
    data: relacionados,
  } = useSWR(`wp/v2/posts_relacionados?slug=${params.slug}`, url =>
    api.get(url)
  );
  const { data: links } = useSWR(`/wp/v2/links_blog/${params.slug}`, url =>
    api.get(url)
  );

  function linkPosts(link) {
    if (!link) return null;
    scrollbar.scrollToTop();
    return history.push(`/blog/${link}`);
  }

  function disabledLink(link) {
    if (!link) return true;
    return false;
  }

  return (
    <Scrollbars ref={ref => (scrollbar = ref)} autoHide universal>
      <Header />

      {!data && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
            paddingBottom: 60,
          }}
        >
          <ReactLoading type="spin" color="#229536" height={60} width={60} />
        </div>
      )}

      {!!data && (
        <Container className="container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
            <Breadcrumb.Item href={`/blog`}>Blog</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.data[0].title.rendered}</Breadcrumb.Item>
          </Breadcrumb>
          <Blog>
            <Side>
              <h3>Postagens Recentes</h3>
              {relacionados?.data.relacionados.map(item => (
                <Link key={item.slug} to={`/blog/${item.slug}`}>
                  <Item>
                    <img src={item.imagem} alt={item.titulo} />
                    <h5>{item.titulo}</h5>
                    <div />
                  </Item>
                </Link>
              ))}
            </Side>
            <Content>
              <Post>
                <figure>
                  <img
                    src={
                      !!data?.data[0]._embedded &&
                      !!data?.data[0]._embedded['wp:featuredmedia'] &&
                      data?.data[0]._embedded['wp:featuredmedia'][0].source_url
                    }
                    alt={
                      !!data?.data[0]._embedded &&
                      !!data?.data[0]._embedded['wp:featuredmedia'] &&
                      !!data?.data[0]._embedded['wp:featuredmedia'][0].title &&
                      data?.data[0]._embedded['wp:featuredmedia'][0].title
                        .rendered
                    }
                  />
                  <div>
                    {!!data?.data && format(new Date(data.data[0].date), 'dd')}
                    <br />
                    <span>
                      {!!data?.data &&
                        format(new Date(data.data[0].date), 'MMM', {
                          locale: ptBR,
                        })}
                    </span>
                  </div>
                </figure>
                <div>
                  {htmlParser(data?.data[0].content.rendered)}
                  <div className="post--actions">
                    <div className="action--share">
                      <p>Compartilhar:</p>
                      <a
                        href={`https://www.facebook.com/sharer.php?u=https://basesolida.com.br/blog/${data?.data[0].slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TiSocialFacebook size={25} color="#229534" />
                      </a>
                      <a
                        href={`http://www.twitter.com/share?url=https://basesolida.com.br/blog/${data?.data[0].slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TiSocialTwitter size={25} color="#229534" />
                      </a>
                    </div>
                    <div className="action--nav">
                      <div
                        role="presentation"
                        onClick={() => linkPosts(links?.data.links.anterior)}
                        disabled={disabledLink(links?.data.links.anterior)}
                      >
                        <IoIosArrowBack size={15} color="#229534" />
                        <p>Anterior</p>
                      </div>
                      <div
                        role="presentation"
                        onClick={() => linkPosts(links?.data.links.proximo)}
                        disabled={disabledLink(links?.data.links.proximo)}
                      >
                        <p>Próxima</p>
                        <IoIosArrowForward size={15} color="#229534" />
                      </div>
                    </div>
                  </div>
                </div>
              </Post>
            </Content>
          </Blog>
        </Container>
      )}
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
