import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  position: ${props => (props.isOpen ? 'fixed' : 'absolute')};
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 130px;
  margin-top: 20px !important;
  z-index: 9;

  @media (max-width: 480px) {
    margin-top: 0 !important;
  }

  > a {
    @media (max-width: 480px) {
      /* display: none; */
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    @media (max-width: 480px) {
      max-width: 100%;

      img {
        z-index: 99;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      @media (max-width: 480px) {
        display: none;
      }

      div {
        margin-right: 65px;

        form {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 35px;
            transform: translateY(-50%);
            width: 1px;
            height: 17px;
            background: #fff;
          }

          input {
            width: 100%;
            height: 35px;
            font-weight: normal;
            font-size: 12px;
            line-height: 35px;
            color: #fff;
            background: transparent;
            border: none;

            &::placeholder {
              font-weight: normal;
              font-size: 12px;
              line-height: 35px;
              color: #fff;
            }
          }

          svg {
            cursor: pointer;
          }
        }
      }

      p {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 25px;
        letter-spacing: 0.923077px;
        color: #fff;
        white-space: nowrap;
        margin-bottom: 0;

        a {
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;

      list-style: none;

      @media (max-width: 480px) {
        position: absolute;
        top: ${props => (props.isOpen ? '0' : '-1000px')};
        left: -2.5%;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100vw;
        height: 100vh;
        background: #3f2f2f;
        transition: all ease-in-out 0.5s;
        /* z-index: 999; */
      }

      span {
        background: #cf9c66;
        position: relative;
        top: -6px;
        padding: 2px 3px;
        font-size: 11px;
        text-transform: lowercase;
      }

      .item--outline {
        background: #cf9c66;
        padding: 5px 10px;

        a:after {
          display: none;
        }
      }

      li {
        margin-left: 40px;

        @media (max-width: 480px) {
          margin: 0;
          margin-top: 20px;
        }

        &:first-of-type {
          margin: 0;
        }

        &:hover {
          a:after {
            bottom: -8px;
            background: #fff;
          }
        }

        a {
          position: relative;
          font-weight: 600;
          font-size: 14px;
          line-height: 25px;
          letter-spacing: 1.15385px;
          text-transform: uppercase;
          color: #fff;

          &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translateX(-50%);
            width: 110%;
            height: 4px;
            background: transparent;
            transition: ease 0.3s;
          }
        }
      }
    }
  }
`;

export const Hamburger = styled.div`
  display: none !important;
  z-index: 99;

  @media (max-width: 480px) {
    display: unset !important;
  }

  .hamburger-inner {
    background-color: #fff !important;

    &:before,
    &:after {
      background-color: #fff !important;
    }
  }
`;

export const BannerBox = styled.div`
  width: 100%;
  max-height: 95vh;
  min-height: 200px;
  background: #403421;

  @media (max-width: 480px) {
    height: auto;
    min-height: 150px;
    background: #403421;
  }
`;

export const Banner = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${props => props.bg}) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 95vh;
  z-index: 8;

  @media (max-width: 480px) {
    height: 50vh;

    &.bannerFerias {
      background-size: auto;
      background-position: 80% center;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      180deg,
      rgba(44, 35, 35, 0.986014) 0%,
      rgba(0, 0, 0, 0.0001) 79.44%
    );
    mix-blend-mode: normal;
    opacity: 0.7;
  }

  div {
    @media (max-width: 480px) {
      width: 95% !important;
      margin: 0 auto 0 auto;
    }

    h2 {
      font-weight: 300;
      font-size: 36px;
      line-height: 25px;
      text-align: center;
      color: #ffefda;
      margin-bottom: 30px;

      @media (max-width: 480px) {
        font-size: 30px;
        line-height: 30px;
      }
    }

    h3 {
      font-weight: 900;
      font-size: 61px;
      line-height: 25px;
      text-align: center;
      color: #f6f0da;

      @media (max-width: 480px) {
        font-size: 45px;
        line-height: 40px;
      }
    }

    p {
      font-weight: 900;
      font-size: 25px;
      line-height: 25px;
      letter-spacing: 1.92308px;
      text-align: center;
      color: #fff;
      margin-top: 30px;

      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
  }
`;

export const Produtos = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 65px;
  margin-top: 70px !important;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const Side = styled.aside`
  .side--search {
    form {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 65px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        width: 1px;
        height: 17px;
        background: #000;
      }

      input {
        width: 100%;
        height: 35px;
        font-weight: normal;
        font-size: 15px;
        line-height: 35px;
        color: #000;
        background: transparent;
        border: none;

        &::placeholder {
          font-weight: normal;
          font-size: 15px;
          line-height: 35px;
          color: #000;
        }
      }

      svg {
        cursor: pointer;
      }
    }
  }

  h3 {
    position: relative;
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #3f2f2f;
    margin-top: 60px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 103%;
      transform: translateY(-50%);
      width: 500px;
      height: 50%;
      background: #229536;

      @media (max-width: 480px) {
        right: 101%;
      }
    }
  }

  .side--categorias {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    a {
      position: relative;
      font-weight: normal;
      font-size: 15px;
      line-height: 38px;
      text-transform: uppercase;
      color: #000;

      &:hover:after {
        bottom: 5px;
        background: #000;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 30px;
        height: 1px;
        background: transparent;
        transition: ease 0.3s;
      }
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  > div {
  }
`;

export const Produto = styled.div`
  cursor: pointer;

  &:hover {
    .border:before,
    .border:after {
      width: 100%;
    }

    .border div:before,
    .border div:after {
      height: 100%;
    }

    img {
      opacity: 0.7;
    }
  }

  .square {
    max-width: 300px;
    max-height: 300px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    @media (max-width: 480px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.4s ease-in-out;
  }

  h4 {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #000;
    margin-top: 20px;
  }

  > div {
    position: relative;
    background: #000;

    .border {
      position: absolute;
      opacity: 0.45;
    }

    .border:before,
    .border:after,
    .border div:before,
    .border div:after {
      background-color: #fff;
      position: absolute;
      content: '';
      display: block;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    .border:before,
    .border:after {
      width: 0;
      height: 1px;
    }

    .border div:before,
    .border div:after {
      width: 1px;
      height: 0;
    }

    .border.one {
      left: 10px;
      top: 10px;
      right: 22px;
      bottom: 22px;
    }

    .border.one:before,
    .border.one div:before {
      left: 0;
      top: 0;
    }

    .border.one:after,
    .border.one div:after {
      bottom: 0;
      right: 0;
    }

    .border.two {
      left: 22px;
      top: 22px;
      right: 10px;
      bottom: 10px;
    }

    .border.two:before,
    .border.two div:before {
      right: 0;
      top: 0;
    }

    .border.two:after,
    .border.two div:after {
      bottom: 0;
      left: 0;
    }
  }
`;

export const Sobre = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 130px !important;

  @media (max-width: 480px) {
    flex-direction: column;
    width: 100% !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 125px;
    left: 14%;
    width: 30%;
    height: 18px;
    background: #229534;
    z-index: 10;

    @media (max-width: 480px) {
      display: none;
    }
  }

  img {
    position: relative;
    width: 40%;
    object-fit: contain;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  > div {
    position: relative;
    width: 65%;
    background: #fff3e4;
    margin-left: -5%;
    padding: 100px 60px 230px calc(5% + 80px);
    z-index: -1;

    @media (max-width: 480px) {
      width: 100%;
      padding: 100px 2.5% 170px 2.5%;
      margin: 0;
    }

    h2 {
      font-weight: 900;
      font-size: 55px;
      line-height: 65px;
      text-transform: uppercase;
      color: #000;

      @media (max-width: 480px) {
        text-align: center;
      }
    }

    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 35px;
      color: #000;
    }

    > div {
      position: absolute;
      width: calc(95% - 140px);
      background: #229534;
      padding: 65px 40px;
      margin-top: 60px;

      @media (max-width: 480px) {
        left: 0;
        width: 100%;
        padding: 20px 2.5%;
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2.5px 50px;
        list-style: none;
        margin: 0;

        @media (max-width: 480px) {
          grid-template-columns: 1fr;
        }

        li {
          position: relative;
          font-weight: bold;
          font-size: 15px;
          line-height: 35px;
          color: #fff;
          margin-left: 55px;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -55px;
            transform: translateY(-50%);
            width: 45px;
            height: 3px;
            background: #3f2f2f;
          }
        }
      }
    }
  }
`;

export const Vendidos = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
  align-items: center;

  height: 470px;
  margin-top: 195px !important;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    height: unset;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 30px;
    left: -2%;
    width: 103%;
    height: 13px;
    background: #3f2f2f;

    @media (max-width: 480px) {
      display: none;
    }
  }

  > div:first-of-type {
    width: 100%;
    height: 100%;
    background: #229534;

    h2 {
      font-weight: 900;
      font-size: 55px;
      line-height: 49px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      margin-top: 228px;
    }
  }
`;
