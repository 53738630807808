import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 185px !important;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  h2 {
    grid-column: span 3;
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #3f2f2f;

    @media (max-width: 480px) {
      grid-column: unset;
    }
  }
  a {
    .active > div {
      bottom: -35px;
      opacity: 1;
    }

    > div {
      position: relative;
      height: 530px;
      cursor: pointer;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 8;
      }

      > div {
        position: absolute;
        right: 0;
        bottom: -70px;
        display: flex;
        align-items: center;
        width: 98%;
        background: #fff;
        padding: 55px 0 55px 25px;
        z-index: 9;
        opacity: 0;
        transition: all ease 0.5s;

        @media (max-width: 480px) {
          bottom: 0 !important;
          opacity: 1;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 95%;
          height: 18px;
          background: #229534;
        }

        h2 {
          max-width: 60%;
          font-weight: 900;
          font-size: 20px;
          line-height: 19px;
          text-transform: uppercase;
          color: #000;
          margin: 0;
        }

        > div {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 115%;
          background: #3f2f2f;
          padding: 0 45px;
          z-index: 10;

          p {
            font-weight: 900;
            font-size: 45px;
            line-height: 30px;
            text-transform: uppercase;
            color: #fff;
            margin: 0;

            span {
              font-size: 26px;
            }
          }
        }
      }
    }
  }
`;
