import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 25px !important;

  .ant-breadcrumb {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.923077px;
      text-transform: uppercase;
      color: #3f2f2f;

      a {
        color: #3f2f2f;

        &:hover {
          color: #229536;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;
  margin-top: 45px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  > div {
    h2 {
      font-weight: 800;
      font-size: 30px;
      line-height: 30px;
      color: #229536;
    }

    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 35px;
      color: #000;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 70px;

      > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        @media (max-width: 480px) {
          grid-template-columns: 1fr;
        }
      }

      .input--group {
        display: flex;
        flex-direction: column;
      }

      label {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #000;
        margin-top: 30px;
        margin-bottom: 10px;
      }

      input,
      textarea {
        font-size: 15px;
        line-height: 35px;
        color: #000;
        border: none;
        border-bottom: 1px solid #000;
      }

      span {
        font-size: 12px;
        color: #a63232;
        margin-top: 5px;
      }

      .submit-btn {
        display: flex;
        justify-content: center;
        position: relative;

        > div {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          z-index: -1;
        }

        .disabled {
          z-index: 1;
        }
      }

      button {
        align-self: center;
        height: 83px;
        font-weight: 900;
        font-size: 20px;
        text-transform: uppercase;
        color: #229534;
        background: transparent;
        border: 3px solid #229534;
        padding: 0 50px;
        margin-top: 20px;
        transition: all ease-in-out 0.4s;
        cursor: pointer;

        &:hover,
        &:focus {
          background: #229534;
          color: #fff;
        }
      }
    }
  }
  img {
    width: 100%;

    @media (max-width: 480px) {
      display: none;
    }
  }
`;

export const Basesolida = styled.div`
  position: relative;
  margin-top: 60px;

  iframe {
    border: none;
  }
`;

export const Mapa = styled.div`
  position: relative;
  margin-top: 60px;
`;
