import React, { useState } from 'react';
import useSWR from 'swr';
import { Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb, Modal, notification } from 'antd';
import htmlParser from 'react-html-parser';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';

// Icons
import { IoIosSearch } from 'react-icons/io';

// Services
import api from '../../services/api';
import ademail from '../../services/ademail';

// Styles
import {
  Container,
  Products,
  Side,
  Content,
  Product,
  Release,
  Solicitacao,
} from './styles';

// Components
import Header from '../../components/Header';
import Orcamento from '../../components/Orcamento';
import Parceiros from '../../components/Parceiros';
import Footer from '../../components/Footer';

export default function Produto({ match: { params } }) {
  // State
  const [selectedButton, setSelectedButton] = useState('descricao');
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  // History
  const history = useHistory();

  // Requests
  const { data } = useSWR(`/wp/v2/produtos?slug=${params.slug}`, url =>
    api.get(url)
  );

  const schema = Yup.object().shape({
    name: Yup.string().required('Campo nome é obrigatório'),
    email: Yup.string()
      .email('Insira um email válido')
      .required('Campo nome é obrigatório'),
    tel: Yup.string().required('Campo telefone é obrigatório'),
    qtd: Yup.string().required('Campo quantidade é obrigatório'),
    obs: Yup.string().required('Campo observações é obrigatório'),
  });

  async function handleSubmit(req, { resetForm }) {
    const body = `
      <p>Interesse em: ${data?.data.produtos[0].nome}</p>
      <p>Nome: ${req.name}</p>
      <p>Email: ${req.email}</p>
      <p>Telefone: ${req.tel}</p>
      <p>Quantidade: ${req.qtd}</p>
      <p>Observações: ${req.obs}</p>
    `;

    const email = {
      to: 'basesolida@basesolida.com.br',
      from: req.email,
      as: req.name,
      subject: 'Novo interesse em orçamento - Base Sólida',
      message: body,
    };

    try {
      setDisabledButton(true);
      await ademail.post('/email', email);
      resetForm();
      setIsModalOpen(false);
      setDisabledButton(false);
      notification.success({
        message: 'Orçamento solicitado com sucesso!!',
        description: 'Entraremos em contato em breve...',
        placement: 'bottomRight',
      });
    } catch (err) {
      setDisabledButton(false);
      notification.error({
        message: 'Oops, algo deu errado!!',
        description: 'Verifique os campos e tente novamente.',
        placement: 'bottomRight',
      });
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    history.push(`/busca/${search}`);
  }

  return (
    <Scrollbars autoHide universal>
      <Header />

      {!data && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
            paddingBottom: 60,
          }}
        >
          <ReactLoading type="spin" color="#229536" height={60} width={60} />
        </div>
      )}

      {!!data && (
        <Container className="container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/produtos">Produtos</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.data.produtos[0].nome}</Breadcrumb.Item>
          </Breadcrumb>
          <Products>
            <Side>
              <div className="side--search">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    placeholder="PESQUISAR"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                  />
                  <IoIosSearch size={25} color="#000" onClick={handleSearch} />
                </form>
              </div>
              <h3>Categorias</h3>
              <div className="side--categorias">
                {data?.data.categorias_de_produtos.map(categoria => (
                  <Link key={categoria.slug} to={`/produtos/${categoria.slug}`}>
                    {categoria.name}
                  </Link>
                ))}
              </div>
            </Side>
            <Content>
              <Product>
                <div>
                  <h3>{htmlParser(data?.data.produtos[0].nome)}</h3>
                  <img
                    src={data?.data.produtos[0].imagem_nova}
                    alt={data?.data.produtos[0].nome}
                  />
                  <br />
                  <button type="button" onClick={() => setIsModalOpen(true)}>
                    Solicitar Orçamento
                  </button>
                </div>
              </Product>
              <Release>
                <div className="button--group">
                  <button
                    type="button"
                    className={selectedButton === 'descricao' ? 'active' : ''}
                    onClick={() => setSelectedButton('descricao')}
                  >
                    Descrição
                  </button>
                  {!!data?.data.produtos[0].onde_usar && (
                    <button
                      type="button"
                      className={selectedButton === 'ondeusar' ? 'active' : ''}
                      onClick={() => setSelectedButton('ondeusar')}
                    >
                      Onde Usar
                    </button>
                  )}
                </div>
                <div className="release--content">
                  <div className="release--content__description">
                    {!!data?.data.produtos &&
                      selectedButton === 'descricao' &&
                      htmlParser(data?.data.produtos[0].descricao)}

                    {!!data?.data.produtos &&
                      selectedButton === 'ondeusar' &&
                      htmlParser(data?.data.produtos[0].onde_usar)}
                  </div>
                </div>
              </Release>
            </Content>
          </Products>
        </Container>
      )}
      <Modal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Solicitacao>
          <h4>Solicitar orçamento</h4>
          <p>
            Solicitar orçamento para &quot;
            {data?.data.produtos[0].nome}&quot;
          </p>
          <Form onSubmit={handleSubmit} schema={schema}>
            <div>
              <div>
                <Input type="text" name="name" label="Nome" />
              </div>
              <div>
                <Input type="email" name="email" label="Email" />
              </div>
            </div>
            <div>
              <div>
                <Input type="tel" name="tel" label="Telefone" />
              </div>
              <div>
                <Input type="text" name="qtd" label="Quantidade" />
              </div>
            </div>
            <Input
              multiline
              rows={3}
              type="text"
              name="obs"
              label="Observações"
            />
            <div className="submit-btn">
              <div className={disabledButton ? 'disabled' : ''} />
              <button type="submit" disabled={disabledButton}>
                Enviar
              </button>
            </div>
          </Form>
        </Solicitacao>
      </Modal>
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
