import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 25px !important;

  .ant-breadcrumb {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.923077px;
      text-transform: uppercase;
      color: #3f2f2f;

      a {
        color: #3f2f2f;

        &:hover {
          color: #229536;
        }
      }
    }
  }
`;

export const Products = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 65px;
  margin-top: 70px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const Side = styled.aside`
  .side--search {
    form {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 65px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        width: 1px;
        height: 17px;
        background: #000;
      }

      input {
        width: 100%;
        height: 35px;
        font-weight: normal;
        font-size: 15px;
        line-height: 35px;
        color: #000;
        background: transparent;
        border: none;

        &::placeholder {
          font-weight: normal;
          font-size: 15px;
          line-height: 35px;
          color: #000;
        }
      }

      svg {
        cursor: pointer;
      }
    }
  }

  h3 {
    position: relative;
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #3f2f2f;
    margin-top: 60px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 103%;
      transform: translateY(-50%);
      width: 500px;
      height: 50%;
      background: #229536;
    }
  }

  .side--categorias {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    a {
      position: relative;
      font-weight: normal;
      font-size: 15px;
      line-height: 38px;
      text-transform: uppercase;
      color: #000;

      &:hover:after {
        bottom: 5px;
        background: #000;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 30px;
        height: 1px;
        background: transparent;
        transition: ease 0.3s;
      }
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 480px) {
    display: block;
  }

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}
`;

export const Description = styled.div`
  grid-column: span 3;

  @media (max-width: 480px) {
    grid-column: span 2;
  }

  h3 {
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    color: #229536;

    span {
      color: #3f2f2f;
    }
  }

  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 38px;
    color: #000;
  }
`;

export const Product = styled.div`
  cursor: pointer;

  &:hover {
    .border:before,
    .border:after {
      width: 100%;
    }

    .border div:before,
    .border div:after {
      height: 100%;
    }

    img {
      opacity: 0.7;
    }
  }

  .square {
    max-width: 300px;
    max-height: 300px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    @media (max-width: 480px) {
      max-width: 100%;
      max-height: 100%;
      margin-top: 50px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.4s ease-in-out;
  }

  h4 {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #000;
    margin-top: 20px;
  }

  > div {
    position: relative;
    background: #000;

    .border {
      position: absolute;
      opacity: 0.45;
    }

    .border:before,
    .border:after,
    .border div:before,
    .border div:after {
      background-color: #fff;
      position: absolute;
      content: '';
      display: block;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    .border:before,
    .border:after {
      width: 0;
      height: 1px;
    }

    .border div:before,
    .border div:after {
      width: 1px;
      height: 0;
    }

    .border.one {
      left: 10px;
      top: 10px;
      right: 22px;
      bottom: 22px;
    }

    .border.one:before,
    .border.one div:before {
      left: 0;
      top: 0;
    }

    .border.one:after,
    .border.one div:after {
      bottom: 0;
      right: 0;
    }

    .border.two {
      left: 22px;
      top: 22px;
      right: 10px;
      bottom: 10px;
    }

    .border.two:before,
    .border.two div:before {
      right: 0;
      top: 0;
    }

    .border.two:after,
    .border.two div:after {
      bottom: 0;
      left: 0;
    }
  }
`;

export const Paginacao = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 480px) {
    grid-column: unset;
    justify-content: center;
  }

  .ant-pagination-item:hover {
    border-color: #229536;

    a {
      color: #229536;
    }
  }

  .ant-pagination-item-active {
    border-color: #3f2f2f;

    a {
      color: #3f2f2f;
    }
  }

  .ant-pagination-next:hover a,
  .ant-pagination-prev:hover a,
  .ant-pagination-next:focus a,
  .ant-pagination-prev:focus a {
    border-color: #229536;
    color: #229536;
  }
`;
