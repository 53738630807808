import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import { Container } from './styles';

export default function Orcamento() {
  return (
    <Container>
      <div className="container">
        <h2>
          Faça já seu <span>orçamento</span> conosco!
        </h2>
        <img
          src={require('../../assets/images/orcamento-banner.png')}
          alt="Orçamento"
        />
        <div />
        <div>
          <Link to="/contato">
            <button type="button">Solicitar orçamento</button>
          </Link>
          <a
            href="https://api.whatsapp.com/send?phone=5541996446157"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button type="button">Chamar no whatsapp</button>
          </a>
        </div>
      </div>
    </Container>
  );
}
