import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Icons
import { IoIosSearch, IoLogoWhatsapp } from 'react-icons/io';

// Styles
import { Container, Gap, Hamburger } from './styles';

export default function Header() {
  // State
  const [search, setSearch] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // History
  const history = useHistory();

  function handleSearch(e) {
    e.preventDefault();
    history.push(`/busca/${search}`);
  }

  return (
    <>
      <Gap isOpen={isMenuOpen} />
      <Container isOpen={isMenuOpen}>
        <div className="container">
          <a href="/">
            <div className="header--logo">
              <img
                src={require('../../assets/images/base-solida-logo.png')}
                alt="Base sólida"
              />
            </div>
          </a>
          <div>
            <div>
              <div>
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    placeholder="PESQUISAR"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                  />
                  <IoIosSearch
                    size={24}
                    color="#3F2F2F"
                    onClick={handleSearch}
                  />
                </form>
              </div>
              <p>
                <a
                  href="https://api.whatsapp.com/send?phone=5541996446157"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoWhatsapp size={20} color="#3F2F2F" />
                </a>
                <strong
                  style={{ fontWeight: 600, fontSize: 18, paddingRight: 10 }}
                >
                  41 99644.6157 | 41 3653.0008 | BASESOLIDA@BASESOLIDA.COM.BR
                </strong>{' '}
              </p>
            </div>
            <ul>
              <li>
                <a href="/sobre">A empresa</a>
              </li>
              <li>
                <a href="/produtos">Produtos</a>
              </li>
              <li>
                <a href="/portfolio/trabalhos-realizados">Portifólio</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.basesolidamadeiras.com/"
                  rel="noreferrer noopener"
                >
                  Outlet/Saldão
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://lojabasesolida.com.br/"
                  rel="noreferrer"
                >
                  Loja Batel <span>novo</span>
                </a>
              </li>
              <li>
                <a href="/contato">Contato</a>
              </li>
            </ul>
          </div>
          <Hamburger>
            <button
              className={`hamburger hamburger--minus ${isMenuOpen &&
                'is-active'}`}
              type="button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </Hamburger>
        </div>
      </Container>
    </>
  );
}
