import { createGlobalStyle } from 'styled-components';

// Thrid-part libraries
import 'normalize.css/normalize.css'; // Normalize
import 'antd/es/style/index.css'; // Global style ant.design
import '@wordpress/block-library/build-style/style.css'; // Gutenberg style for block library
import 'antd/es/breadcrumb/style/index.css';
import 'antd/es/pagination/style/index.css';
import 'antd/es/modal/style/index.css';
import 'antd/es/notification/style/index.css';
import 'antd/es/carousel/style/index.css';
import 'hamburgers/dist/hamburgers.min.css';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,600,700,900&display=swap');

  * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box !important;
  }

  html, body, #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden !important;

    font-family: 'Work Sans';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
  }

  .container {
    width: 90%;
    max-width: 1460px;
    margin: auto;

    @media (max-width: 480px) {
      width: 95%;
    }
  }

  .slick-dots-bottom {
    bottom: 32px !important;
  }

  .hide {
    display: none;
  }

  .ferias  {
    width: 900px !important;
    top: 50% !important;
    transform: translateY(-50%);

    @media screen and (max-width: 480px) {
      width: 95% !important;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .ant-notification-notice-message {
    font-size: 18px !important;
    color: #fff !important;
  }

  .ant-notification-notice {
    background-color: #229534 !important;
  }
  .anticon svg path {
    fill: #fff;
  }
`;

export default GlobalStyle;
