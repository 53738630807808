import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 170px !important;

  @media (max-width: 480px) {
    margin-top: 60px !important;
  }

  h2 {
    font-weight: 900;
    font-size: 25px;
    line-height: 29px;
    text-transform: uppercase;
    color: #000;
  }

  > div {
    width: 100%;
    height: 70px;
    margin-top: 100px;

    @media (max-width: 480px) {
      margin-top: 30px;
    }
  }

  .menu-item-wrapper:first-of-type {
    > div {
      margin-left: 0;
    }
  }
`;

export const Parceiro = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  margin-left: 70px;

  img {
    height: 100%;
    object-fit: contain;
    pointer-events: none;
  }
`;
