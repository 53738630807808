import React from 'react';

// Icons
import { FiPhone, FiMail, FiClock } from 'react-icons/fi';

// Styles
import { Container } from './styles';

export default function Atendimento() {
  return (
    <Container className="container">
      <img
        src={require('../../assets/images/atendimento-banner.jpg')}
        alt="Atendimento"
      />
      <div>
        <h2>Central de Atendimento</h2>
        <div>
          <div>
            <div>
              <FiPhone size={40} color="#fff" />
              <div>
                <p>
                  41 <span>3653 0008</span>
                </p>
                <p>
                  41 <span>99644 6157</span>
                </p>
              </div>
            </div>
            <div>
              <FiMail size={40} color="#fff" />
              <p>
                basesolida@<span>basesolida</span>.com.br
              </p>
            </div>
            <div>
              <FiClock size={40} color="#fff" />
              <p>
                Atendimento de <span>segunda</span> à <span>sexta</span> das{' '}
                <span>8:30</span> às <span>12:00</span> e das <span>13:15</span>{' '}
                às <span>18:00</span> e <span> sábado</span> das{' '}
                <span>9:00</span> às <span>12:00</span>
              </p>
            </div>
          </div>
          <p>
            Na Base Sólida você conta com uma equipe de atendimento altamente
            especializada, entre em contato conosco, faça seus pedidos, tire
            dúvidas e dê sugestões, porque a final de contas a sua opinião é a
            mais importante para nós.
          </p>
        </div>
      </div>
    </Container>
  );
}
