import React, { useState } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// Services
import api from '../../services/api';

// Styles
import { Container } from './styles';

export default function BlogPreview() {
  // State
  // const [posts, setPosts] = useState([]);
  const [activeBlog, setActiveBlog] = useState(0);

  // Requests
  const { data: posts } = useSWR('/wp/v2/posts?_embed&per_page=3', url =>
    api.get(url)
  );

  return (
    <Container className="container">
      <h2>Blog</h2>
      {posts?.data.map((post, index) => (
        <Link key={post.slug} to={`/blog/${post.slug}`}>
          <div
            className={activeBlog === index ? 'active' : ''}
            onMouseEnter={() => setActiveBlog(index)}
          >
            <img
              src={post?._embedded['wp:featuredmedia'][0].source_url}
              alt={post?._embedded['wp:featuredmedia'][0].title.rendered}
            />
            <div>
              <h2>{post?.title.rendered}</h2>
              <div>
                <p>
                  {format(new Date(post?.date), 'dd')}
                  <br />
                  <span>
                    {format(new Date(post?.date), 'MMM', { locale: ptBR })}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </Container>
  );
}
