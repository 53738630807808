import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb, Pagination } from 'antd';
import htmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';

// Icons
import { IoIosSearch } from 'react-icons/io';

// Services
import api from '../../../services/api';

// Styles
import {
  Container,
  Products,
  Side,
  Content,
  Description,
  Product,
  Paginacao,
} from './styles';

// Components
import Header from '../../../components/Header';
import Orcamento from '../../../components/Orcamento';
import Parceiros from '../../../components/Parceiros';
import Footer from '../../../components/Footer';

export default function Categoria({ match: { params } }) {
  // State
  const [search, setSearch] = useState('');
  const [content, setContent] = useState(null);
  const [pagination, setPagination] = useState({ total: 0 });

  // Refs
  const scrollbarRef = useRef(null);

  // History
  const history = useHistory();

  // Requests
  useEffect(() => {
    async function getProducts() {
      const { data } = await api.get(`/wp/v2/produtos?cat=${params.category}`);
      setContent(data);
      setPagination({ total: data.total_de_produtos });
    }

    getProducts();
  }, [params]);

  function handleSearch(e) {
    e.preventDefault();
    history.push(`/busca/${search}`);
  }

  async function paginate(page) {
    const { data } = await api.get(
      `/wp/v2/produtos?cat=${content?.categoria.slug}&page=${page}`
    );
    scrollbarRef.current.scrollTop(
      document.getElementById('content').offsetTop
    );

    setContent(data);
  }

  return (
    <Scrollbars autoHide universal ref={scrollbarRef}>
      <Header />

      {!content && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
            paddingBottom: 60,
          }}
        >
          <ReactLoading type="spin" color="#229536" height={60} width={60} />
        </div>
      )}

      {!!content && (
        <Container className="container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
            <Breadcrumb.Item href={`/produtos`}>Produtos</Breadcrumb.Item>
            <Breadcrumb.Item>{content?.categoria.name}</Breadcrumb.Item>
          </Breadcrumb>
          <Products>
            <Side>
              <div className="side--search">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    placeholder="PESQUISAR"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                  />
                  <IoIosSearch size={25} color="#000" onClick={handleSearch} />
                </form>
              </div>
              <h3>Categorias</h3>
              <div className="side--categorias">
                {content?.categorias_de_produtos.map(categoria => (
                  <Link key={categoria.slug} to={`/produtos/${categoria.slug}`}>
                    {categoria.name}
                  </Link>
                ))}
              </div>
            </Side>
            <Content id="content">
              <Description>
                <h3>
                  Base Sólida <span>| {content?.categoria.name}</span>
                </h3>
                <p>{content?.categoria.description}</p>
              </Description>
              {content?.produtos.map(produto => (
                <a key={produto.slug} href={`/produto/${produto.slug}`}>
                  <Product>
                    <div className="square">
                      <img
                        src={
                          produto.imagem_nova
                            ? produto.imagem_nova
                            : produto.imagem
                        }
                        alt={produto.nome}
                      />
                      <div className="border one">
                        <div />
                      </div>
                      <div className="border two">
                        <div />
                      </div>
                    </div>
                    <h4>{htmlParser(produto.nome)}</h4>
                  </Product>
                </a>
              ))}
            </Content>
            <Paginacao>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={15}
                total={Number(pagination.total)}
                onChange={paginate}
              />
            </Paginacao>
          </Products>
        </Container>
      )}
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
