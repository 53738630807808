import React from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Breadcrumb } from 'antd';
import htmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';

// Services
import api from '../../services/api';

// Utils
// Não vou desistir, preciso ficar rico
import stripTags from '../../utils/stripTags';

// Styles
import { Container, Blog, Content, Post } from './styles';

// Components
import Header from '../../components/Header';
import Orcamento from '../../components/Orcamento';
import Parceiros from '../../components/Parceiros';
import Footer from '../../components/Footer';

export default function Blogs() {
  // Requests
  const { data } = useSWR('/wp/v2/posts?_embed', url => api.get(url));

  return (
    <Scrollbars autoHide universal>
      <Header />

      {!data && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
            paddingBottom: 60,
          }}
        >
          <ReactLoading type="spin" color="#229536" height={60} width={60} />
        </div>
      )}

      {!!data && (
        <Container className="container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item>Blog</Breadcrumb.Item>
          </Breadcrumb>
          <Blog>
            <Content>
              <h2>Blog</h2>
              {data?.data.map(post => (
                <Post>
                  <img
                    src={
                      !!post._embedded &&
                      !!post._embedded['wp:featuredmedia'] &&
                      post._embedded['wp:featuredmedia'][0].source_url
                    }
                    alt={
                      !!post._embedded &&
                      !!post._embedded['wp:featuredmedia'] &&
                      !!post._embedded['wp:featuredmedia'][0].title &&
                      post._embedded['wp:featuredmedia'][0].title.rendered
                    }
                  />
                  <h3>{post.title.rendered}</h3>
                  <p>
                    {stripTags(post.content.rendered).length < 400
                      ? htmlParser(stripTags(post.content.rendered))
                      : `${htmlParser(
                          stripTags(post.content.rendered).substring(0, 400)
                        )}[...]`}
                  </p>
                  <Link to={`/blog/${post.slug}`}>
                    <button type="button">Continuar lendo</button>
                  </Link>
                </Post>
              ))}
            </Content>
          </Blog>
        </Container>
      )}
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
