import React, { useState } from 'react';
import { notification } from 'antd';
import { Input } from '@rocketseat/unform';
import * as Yup from 'yup';

// Icons
import { FiPhone, FiMail, FiClock } from 'react-icons/fi';
import { TiSocialFacebook, TiSocialInstagram } from 'react-icons/ti';
import { IoLogoWhatsapp } from 'react-icons/io';

// Services
import ademail from '../../services/ademail';

// Styles
import { Container, Contato, Sobre, Unform, Ade } from './styles';

export default function Footer() {
  const [disabledButton, setDisabledButton] = useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required('Campo nome é obrigatório'),
    email: Yup.string()
      .email()
      .required('Campo email é obrigatório'),
  });

  async function handleSubmit(data, { resetForm }) {
    const body = `
      <p>Nome: ${data.name}</p>
      <p>Email: ${data.email}</p>
    `;

    const email = {
      to: 'basesolida@basesolida.com.br',
      from: data.email,
      as: data.name,
      subject: 'Novo interesse em newsletter - Base Sólida',
      message: body,
    };

    try {
      setDisabledButton(true);
      await ademail.post('/email', email);
      resetForm();
      setDisabledButton(false);
      notification.success({
        message: 'Email enviado com sucesso!!',
        placement: 'bottomRight',
      });
    } catch (err) {
      setDisabledButton(false);
      notification.error({
        message: 'Oops, algo deu errado!!',
        description: 'Verifique os campos e tente novamente.',
        placement: 'bottomRight',
      });
    }
  }

  return (
    <>
      <Container>
        <div className="container">
          <Contato>
            <div>
              <FiPhone size={40} color="#fff" />
              <div>
                <p>
                  41 <span>3653 0008</span>
                </p>
                <p>
                  41 <span>99644 6157</span>
                </p>
              </div>
            </div>
            <div>
              <FiMail size={40} color="#fff" />
              <p>
                basesolida@<span>basesolida</span>.com.br
              </p>
            </div>
            <div>
              <FiClock size={40} color="#fff" />
              <p>
                Atendimento: <br />
                <br />
                <span>segunda</span> à <span>sexta</span> das <span>8:30</span>{' '}
                às <span>12:00</span> e das <span>13:15</span> às{' '}
                <span>18:00</span>
                <br />
                <br />
                <span>sábado</span> das <span>9:00</span> às <span>12:00</span>
              </p>
            </div>
          </Contato>
          <Sobre>
            <img
              src={require('../../assets/images/base-solida-logo.png')}
              alt="Base sólida"
            />
            <p>
              A empresa possui um estoque com 24 especies que são usadas na
              fabricação de móveis, janelas, escadas, assoalhos, dentre outros
              produtos.
            </p>
            <div>
              <a
                href="https://www.facebook.com/madeireirabasesolida/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialFacebook size={30} color="#fff" />
              </a>
              <a
                href="https://www.instagram.com/basesolidamadeiras/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiSocialInstagram size={30} color="#fff" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5541996446157"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoWhatsapp size={30} color="#fff" />
              </a>
            </div>
            <p>Madeireira Base Sólida. Todos os Direitos Reservados!</p>
          </Sobre>
          <Unform onSubmit={handleSubmit} schema={schema}>
            <h3>Receba nossas ofertas em primeira mão!</h3>
            <Input type="text" label="Nome" name="name" />
            <Input type="email" label="Email" name="email" />
            <div className="submit-btn">
              <div className={disabledButton ? 'disabled' : ''} />
              <button type="submit" disabled={disabledButton}>
                Enviar
              </button>
            </div>
          </Unform>
        </div>
      </Container>
      <Ade>
        <a
          href="https://www.agenciaade.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          Desenvolvido com
          <img src={require('../../assets/images/heart.svg')} alt="Coração" />
          por agenciaade.ag
        </a>
      </Ade>
    </>
  );
}
