import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Pages
import Home from '../pages/Home';
import Sobre from '../pages/Sobre';
import Produtos from '../pages/Produtos';
import Categorias from '../pages/Produtos/Categorias';
import Produto from '../pages/Produto';
import Portifolio from '../pages/Portifolio';
import Postfolio from '../pages/Portifolio/Slug';
import Blog from '../pages/Blog';
import Post from '../pages/Blog/Post';
import Contato from '../pages/Contato';
import Busca from '../pages/Busca';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/sobre" component={Sobre} />
      <Route path="/produtos" exact component={Produtos} />
      <Route path="/produtos/:category" component={Categorias} />
      <Route path="/produto/:slug" component={Produto} />
      <Route path="/portfolio" exact component={Portifolio} />
      <Route path="/portfolio/:slug" component={Postfolio} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:slug" component={Post} />
      <Route path="/contato" component={Contato} />
      <Route path="/busca/:search" component={Busca} />
    </Switch>
  );
}
