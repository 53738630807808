/* eslint-disable import/first */
import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Routes from './routes';
import history from './services/history';

// Global Styles
import GlobalStyle from './styles/global';

function App() {
  // TagManager
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-PNQ9D57' });
  }, []);

  return (
    <Router history={history}>
      <Routes />
      <GlobalStyle />
    </Router>
  );
}

export default hot(App);
