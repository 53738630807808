import { Carousel } from 'antd';
import { differenceInYears } from 'date-fns';
import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import htmlParser from 'react-html-parser';
import ReactLoading from 'react-loading';
import { Link, useHistory } from 'react-router-dom';
import useSWR from 'swr';

// Icons
import { IoIosSearch, IoLogoWhatsapp } from 'react-icons/io';

// Services
import api from '../../services/api';

// Styles
import {
  Banner, BannerBox, Content, Hamburger, Header, Produto, Produtos,
  Side, Sobre,
  Vendidos
} from './styles';

// Components
import Atendimento from '../../components/Atendimento';
import BlogPreview from '../../components/BlogPreview';
import Footer from '../../components/Footer';
import Orcamento from '../../components/Orcamento';
import Parceiros from '../../components/Parceiros';

export default function Home() {
  // State
  // const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Hisory
  const history = useHistory();

  // Requests
  const { data } = useSWR('/wp/v2/home', (url) => api.get(url));

  function handleSearch(e) {
    e.preventDefault();
    history.push(`/busca/${search}`);
  }

  return (
    <Scrollbars autoHide universal>
      <Header className="container" isOpen={isMenuOpen}>
        <Link to="/">
          <div>
            <img
              src={require('../../assets/images/base-solida-logo.png')}
              alt="Base sólida"
            />
          </div>
        </Link>
        <div>
          <div>
            <div>
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  placeholder="PESQUISAR"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <IoIosSearch size={24} color="#fff" onClick={handleSearch} />
              </form>
            </div>
            <p>
              <a
                href="https://api.whatsapp.com/send?phone=5541996446157"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoWhatsapp size={20} color="#fff" />
              </a>
              <strong
                style={{ fontWeight: 600, fontSize: 18, paddingRight: 10 }}
              >
                41 99644.6157 | 41 3653.0008 | BASESOLIDA@BASESOLIDA.COM.BR
              </strong>{' '}
            </p>
          </div>
          <ul>
            <li>
              <Link to="/sobre">A empresa</Link>
            </li>
            <li>
              <Link to="/produtos">Produtos</Link>
            </li>
            <li>
              <Link to="/portfolio">Portifólio</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.basesolidamadeiras.com/"
                rel="noreferrer noopener"
              >
                Outlet/Saldão
              </a>
            </li>
            <li>
              <a target="_blank" href="https://lojabasesolida.com.br/">Loja Batel <span>novo</span></a>
            </li>
            <li>
              <Link to="/contato">Contato</Link>
            </li>
          </ul>
        </div>
        <Hamburger>
          <button
            className={`hamburger hamburger--minus ${
              isMenuOpen && 'is-active'
            }`}
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </Hamburger>
      </Header>

      <BannerBox>
        <Carousel
          dotPosition="bottom"
          adaptiveHeight
          effect="fade"
          autoplay
          autoplaySpeed={5000}
          pauseOnHover={false}
        >
          {data?.data.banners.map((banner) => (
            <Banner key={Math.random()} bg={banner.imagem.sizes.large} />
          ))}
        </Carousel>
      </BannerBox>

      {!data && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
            paddingBottom: 60,
          }}
        >
          <ReactLoading type="spin" color="#229536" height={60} width={60} />
        </div>
      )}

      {!!data && (
        <>
          <Produtos className="container">
            <Side>
              <div className="side--search">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    placeholder="PESQUISAR"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <IoIosSearch size={25} color="#000" onClick={handleSearch} />
                </form>
              </div>
              <h3>Categorias</h3>
              <div className="side--categorias">
                {data?.data.categorias_de_produtos.map((categoria) => (
                  <Link key={categoria.slug} to={`/produtos/${categoria.slug}`}>
                    {categoria.name}
                  </Link>
                ))}
              </div>
            </Side>
            <Content>
              {data?.data.produtos.map((produto) => (
                <a key={produto.slug} href={`/produto/${produto.slug}`}>
                  <Produto>
                    <div className="square">
                      <img
                        src={
                          produto.imagem_nova
                            ? produto.imagem_nova
                            : produto.imagem
                        }
                        alt={produto.titulo}
                      />
                      <div className="border one">
                        <div />
                      </div>
                      <div className="border two">
                        <div />
                      </div>
                    </div>
                    <h4>{htmlParser(produto.titulo)}</h4>
                  </Produto>
                </a>
              ))}
            </Content>
          </Produtos>
          <Sobre className="container">
            <img
              src={require('../../assets/images/sobre-banner.jpg')}
              alt="Sobre nós"
            />
            <div>
              <h2>Sobre nós</h2>
              <p>
                A mais de{' '}
                {differenceInYears(new Date(Date.now()), new Date(2001, 1, 1))}{' '}
                anos no mercado a Madeireira Base Sólida oferece uma grande
                variedade de produtos acabados tais como Vigas, Forros,
                Assoalhos, Decks e muito mais para atender clientes em todo o
                mundo. A Base Sólida possuí estoque com 24 espécies de madeiras
                que são usadas para os mais diversos fins! Atualmente seus
                principais produtos de exportação são o piso maciço envernizado
                (assoalho) e o deck com clientes nos EUA, Europa e Ásia.
              </p>
              <div>
                <ul>
                  <li>Decks de madeira</li>
                  <li>Vigamentos para Telhados</li>
                  <li>Forro de Madeira</li>
                  <li>Pergolas e Pergolados</li>
                  <li>Portas Externas e Internas</li>
                </ul>
              </div>
            </div>
          </Sobre>
          <Vendidos className="container">
            <div>
              <h2>Em destaque</h2>
            </div>
            {data?.data.mais_vendidos.map((produto) => (
              <Link key={produto.slug} to={`/produto/${produto.slug}`}>
                <Produto>
                  <>
                    <div className="square">
                      <img
                        src={
                          produto.imagem_nova
                            ? produto.imagem_nova
                            : produto.imagem
                        }
                        alt={produto.titulo}
                      />
                      <div className="border one">
                        <div />
                      </div>
                      <div className="border two">
                        <div />
                      </div>
                    </div>
                    <h4>{htmlParser(produto.titulo)}</h4>
                  </>
                </Produto>
              </Link>
            ))}
          </Vendidos>
          <Atendimento />
          <BlogPreview />
        </>
      )}
      <Orcamento />
      <Parceiros />
      <Footer />
    </Scrollbars>
  );
}
