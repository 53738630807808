import styled from 'styled-components';

export const Gap = styled.div`
  @media (max-width: 480px) {
    height: ${props => (props.isOpen ? '150px' : 'unset')};
  }
`;

export const Container = styled.header`
  position: relative;
  border-bottom: 5px solid #3f2f2f;

  @media (max-width: 480px) {
    position: ${props => (props.isOpen ? 'fixed' : 'relative')};
    top: 0;
    left: 0;
    border: none;
    z-index: 9999;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 95%;
    height: 130px;
    z-index: 9;

    @media (max-width: 480px) {
      /* flex-direction: column; */
      width: 100vw;
      height: 150px;
      background: #3f2f2f;
      padding: 0 2.5%;
    }

    .header--logo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;

      @media (max-width: 480px) {
        z-index: 99;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -115px;
        width: 400%;
        height: 100%;
        background: #3f2f2f;
        z-index: -1;

        @media (max-width: 480px) {
          left: -2.5%;
          right: unset;
          width: 105%;
          display: none;
        }
      }

      img {
        @media (max-width: 480px) {
          margin: 20px 0;
        }
      }
    }
    a {
      height: 100%;

      @media (max-width: 480px) {
        width: 100%;
      }
    }

    > div {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        @media (max-width: 480px) {
          display: none;
        }

        div {
          margin-right: 65px;

          form {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              right: 35px;
              transform: translateY(-50%);
              width: 1px;
              height: 17px;
              background: #3f2f2f;
            }

            input {
              width: 100%;
              height: 35px;
              font-weight: normal;
              font-size: 12px;
              line-height: 35px;
              color: #3f2f2f;
              background: transparent;
              border: none;

              &::placeholder {
                font-weight: normal;
                font-size: 12px;
                line-height: 35px;
                color: #3f2f2f;
              }
            }

            svg {
              cursor: pointer;
            }
          }
        }

        p {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 25px;
          letter-spacing: 0.923077px;
          color: #3f2f2f;
          white-space: nowrap;
          margin-bottom: 0;

          a {
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
      }

      ul {
        display: flex;
        align-items: center;

        list-style: none;

        @media (max-width: 480px) {
          position: absolute;
          top: ${props => (props.isOpen ? '0' : '-800px')};
          left: 0;
          flex-direction: column;
          justify-content: center;
          width: 100vw;
          height: 100vh;
          background: #3f2f2f;
          transition: all ease-in-out 0.4s;
          z-index: 8;
        }

        span {
          background: #cf9c66;
          position: relative;
          top: -6px;
          padding: 2px 3px;
          font-size: 11px;
          text-transform: lowercase;
        }

        .item--outline {
          background: #cf9c66;
          padding: 5px 10px;

          a:after {
            display: none;
          }
        }

        li {
          margin-left: 40px;

          @media (max-width: 480px) {
            margin: 0;
            margin-top: 20px;
          }

          &:first-of-type {
            margin: 0;
          }

          &:hover {
            a:after {
              bottom: -8px;
              background: #229536;
            }
          }

          a {
            position: relative;
            font-weight: 600;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 1.15385px;
            text-transform: uppercase;
            color: #3f2f2f;

            @media (max-width: 480px) {
              color: #fff;
            }

            &:after {
              content: '';
              position: absolute;
              right: 0;
              bottom: -20px;
              width: 100%;
              height: 4px;
              background: transparent;
              transition: ease 0.3s;
            }
          }
        }
      }
    }
  }
`;

export const Hamburger = styled.div`
  display: none !important;
  width: unset !important;
  z-index: 99;

  @media (max-width: 480px) {
    display: unset !important;
  }

  .hamburger-inner {
    background-color: #fff !important;

    &:before,
    &:after {
      background-color: #fff !important;
    }
  }
`;
