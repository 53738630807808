import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 25px !important;

  .ant-breadcrumb {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.923077px;
      text-transform: uppercase;
      color: #3f2f2f;

      a {
        color: #3f2f2f;

        &:hover {
          color: #229536;
        }
      }
    }
  }
`;

export const Portfolio = styled.div`
  width: 80%;
  margin: 70px auto 0 auto;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  h2 {
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    color: #229536;
    margin-bottom: -25px;
  }
`;

export const Post = styled.div`
  position: relative;
  padding-bottom: 65px;
  margin-top: 70px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
  }

  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  h3 {
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    color: #000;
    margin: 0;
    margin-top: 40px;
  }

  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 38px;
    color: #000;
    margin-top: 30px;
  }

  button {
    height: 85px;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #229536;
    background: transparent;
    border: 3px solid #229534;
    padding: 0 50px;
    margin: 0;
    margin-top: 30px;
    cursor: pointer;
    transition: all ease-in-out 0.4s;

    @media (max-width: 480px) {
      width: 100%;
      padding: 0;
    }

    &:hover {
      color: #fff;
      background: #229534;
    }
  }
`;
