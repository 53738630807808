import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 425px;
  background: #229534;
  margin-top: 235px !important;

  @media (max-width: 480px) {
    height: unset;
    margin-top: 40px !important;
  }

  > div {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 100%;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
      padding: 100px 0 370px 0;
    }

    h2 {
      position: relative;
      font-weight: 900;
      font-size: 46px;
      line-height: 43px;
      text-transform: uppercase;
      color: #fff;

      @media (max-width: 480px) {
        text-align: center;
      }

      span {
        color: #fff3e4;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -36px;
        left: 0;
        width: 100%;
        height: 18px;
        background: #3f2f2f;

        @media (max-width: 480px) {
          display: none;
        }
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    div {
      display: flex;
      flex-direction: column;

      a {
        margin-bottom: 20px;

        &:last-of-type {
          margin: 0;
        }
      }

      button {
        width: 100%;
        height: 80px;
        font-weight: 900;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #fff;
        background: transparent;
        border: 3px solid #fff;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        margin-bottom: 20px;

        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
`;
