import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 130px !important;

  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 120px;
    left: 9%;
    width: 30%;
    height: 18px;
    background: #229534;
    z-index: 10;

    @media (max-width: 480px) {
      display: none;
    }
  }

  img {
    position: relative;
    width: 35%;
    object-fit: contain;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  > div {
    position: relative;
    width: 70%;
    background: #3f2f2f;
    margin-left: -5%;
    padding: 75px 60px 50px calc(5% + 80px);
    z-index: -1;

    @media (max-width: 480px) {
      width: 100%;
      padding: 75px 2.5% 50px 2.5%;
      margin: 0;
    }

    h2 {
      font-weight: 900;
      font-size: 55px;
      line-height: 53px;
      text-transform: uppercase;
      color: #fff3e4;

      @media (max-width: 480px) {
        font-size: 40px;
        line-height: 45px;
        text-align: center;
      }
    }

    > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 40px;
      margin-top: 60px;

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      > p {
        font-weight: normal;
        font-size: 15px;
        line-height: 35px;
        color: #fff;
      }

      > div {
        > div {
          display: grid;
          grid-template-columns: 40px 1fr;
          grid-gap: 20px;
          align-items: center;
          margin-top: 30px;

          &:first-of-type {
            margin: 0;
          }

          p {
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            text-transform: uppercase;
            color: #fff3e4;
            margin: 0;

            span {
              font-weight: 900;
            }

            @media (max-width: 480px) {
              font-size: 15px;
              word-break: break-word;

              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;
