import React, { useState } from 'react';
import useSWR from 'swr';
import ScrollMenu from 'react-horizontal-scrolling-menu';

// Services
import api from '../../services/api';

// Styles
import { Container, Parceiro } from './styles';

export default function Parceiros() {
  // State
  const [selected, setSelected] = useState(null);

  // Requests
  const { data: parceiros } = useSWR('/acf/v3/options/options_parceiros', url =>
    api.get(url)
  );

  function renderItems() {
    return parceiros?.data.acf.parceiros.map(parceiro => (
      <Parceiro key={parceiro.id}>
        <img src={parceiro.sizes.large} alt={parceiro.title} />
      </Parceiro>
    ));
  }

  return (
    <Container>
      <h2>Parceiros</h2>
      <ScrollMenu
        data={renderItems()}
        alignCenter={false}
        onSelect={key => setSelected(key)}
        selected={selected}
        scrollToSelected
        wheel={false}
      />
    </Container>
  );
}
