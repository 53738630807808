import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 25px !important;

  .ant-breadcrumb {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.923077px;
      text-transform: uppercase;
      color: #3f2f2f;

      a {
        color: #3f2f2f;

        &:hover {
          color: #229536;
        }
      }
    }
  }

  h2 {
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000;
    margin-top: 50px;

    span {
      color: #229536;
    }
  }

  > p {
    font-weight: normal;
    font-size: 15px;
    line-height: 35px;
    color: #000;

    span {
      font-weight: 800;
    }
  }
`;

export const Servicos = styled.div`
  margin-top: 80px;

  .servicos--top {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 95px;
    background: #3f2f2f;

    @media (max-width: 480px) {
      padding: 0 20px;
    }

    h4 {
      font-weight: 800;
      font-size: 21px;
      line-height: 18px;
      text-align: center;
      color: #fff;
      margin: 0;
    }
  }

  .servicos--bottom {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 95px;
    background: #fff3e4;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      list-style: none;
      margin: 0;
      margin-left: 15px;

      @media (max-width: 480px) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      li {
        position: relative;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #000;

        @media (max-width: 480px) {
          margin-left: 30px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          background: #229536;
        }
      }
    }
  }
`;

export const Preservacao = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 30px;
  margin-top: 90px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%;

    @media (max-width: 480px) {
      width: 60%;
      margin: auto;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      font-weight: bold;
      font-size: 20px;
      line-height: 35px;
      color: #000;
    }

    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 35px;
      color: #000;
    }
  }
`;

export const Empresa = styled.section`
  display: flex;
  align-items: center;
  margin-top: 110px;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  img {
    width: 40%;
    object-fit: contain;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: 65%;
    background: #229536;
    margin-left: -5%;
    padding: 100px 60px 100px calc(5% + 80px);
    z-index: -1;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
      width: 100%;
      margin: 0;
      padding: 50px 5%;
    }

    div {
      display: grid;
      grid-template-columns: 50px 1fr;
      grid-gap: 25px;

      img {
        width: 100%;
      }

      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #fff;

        span {
          font-weight: 800;
        }
      }
    }
  }
`;

export const Mapa = styled.section`
  position: relative;
  margin-top: 125px;
`;
