import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 25px !important;

  .ant-breadcrumb {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.923077px;
      text-transform: uppercase;
      color: #3f2f2f;

      a {
        color: #3f2f2f;

        &:hover {
          color: #229536;
        }
      }
    }
  }
`;

export const Portfolio = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 65px;
  margin-top: 70px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const Side = styled.aside`
  @media (max-width: 480px) {
    display: none;
  }

  .side--search {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 65px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 35px;
      transform: translateY(-50%);
      width: 1px;
      height: 17px;
      background: #000;
    }

    input {
      width: 100%;
      height: 35px;
      font-weight: normal;
      font-size: 15px;
      line-height: 35px;
      color: #000;
      background: transparent;
      border: none;

      &::placeholder {
        font-weight: normal;
        font-size: 15px;
        line-height: 35px;
        color: #000;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  h3 {
    position: relative;
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #3f2f2f;
    /* margin-top: 60px; */
    /* margin-bottom: -25px; */

    &:before {
      content: '';
      position: absolute;
      top: 25%;
      right: 103%;
      transform: translateY(-50%);
      width: 500px;
      height: 30%;
      background: #229536;
    }
  }
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-bottom: 30px;
  margin-top: 45px;
  cursor: pointer;

  &:first-of-type {
    margin: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background: #229534;
  }

  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }

  h5 {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    margin-top: 20px;
  }

  div {
    width: 100%;
    height: 1px;
    background: #000;
    margin: 20px 0;
  }

  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
  }
`;

export const Content = styled.div``;

export const Post = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 900;
    font-size: 35px;
    line-height: 41px;
    color: #000;
  }

  figure {
    position: relative;

    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }

    div {
      position: absolute;
      bottom: -30px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 900;
      font-size: 45px;
      line-height: 35px;
      color: #fff;
      background: #3f2f2f;
      padding: 50px 40px;

      span {
        font-size: 26px;
        text-transform: uppercase;
      }
    }
  }

  > div {
    margin-top: 100px;
    color: #000;

    p {
      line-height: 38px;
    }

    .post--actions {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 120px;
      padding-bottom: 50px;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #000;
      }

      .action--share {
        display: flex;
        align-items: center;

        p {
          font-weight: bold;
          font-size: 15px;
          color: #3f2f2f;
          margin: 0;
        }

        a {
          margin-left: 15px;
        }
      }

      .action--nav {
        display: flex;
        align-items: center;

        > div[disabled] {
          opacity: 0.4;
          cursor: not-allowed;
        }

        > div {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:first-of-type {
            position: relative;
            margin-right: 30px;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              right: -15px;
              transform: translate(-50%, -50%);
              width: 1px;
              height: 40%;
              background: #3f2f2f;
              cursor: initial;
            }

            svg {
              margin: 0;
              margin-right: 10px;
            }
          }

          svg {
            margin-left: 10px;
          }
        }

        p {
          font-weight: bold;
          font-size: 15px;
          color: #3f2f2f;
          margin: 0;
        }
      }
    }
  }
`;
