import styled, { keyframes } from 'styled-components';
import { Form } from '@rocketseat/unform';

export const Container = styled.footer`
  width: 100%;
  background: #3f2f2f;
  padding: 130px 0;
  margin-top: 150px !important;

  @media (max-width: 480px) {
    margin-top: 80px !important;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 130px;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const Contato = styled.div`
  > div {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 20px;
    align-items: center;
    margin-top: 50px;

    &:first-of-type {
      margin: 0;
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #fff3e4;
      margin: 0;

      @media (max-width: 480px) {
        font-size: 15px;
        word-break: break-word;

        br {
          display: none;
        }
      }

      span {
        font-weight: 900;
      }
    }
  }
`;

export const Sobre = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > img {
    width: 100%;
    object-fit: contain;
  }

  p {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    margin-top: 40px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;

      &:first-of-type {
        margin: 0;
      }
    }
  }
`;

export const Unform = styled(Form)`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #fff;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  label {
    font-size: 15px;
    line-height: 25px;
    color: #fff;
    margin-top: 15px;

    &:first-of-type {
      margin-top: 5px;
    }
  }

  input {
    height: 36px;
    font-size: 15px;
    line-height: 36px;
    text-indent: 5px;
    color: #fff;
    background: transparent;
    border: none;
    border-bottom: 0.5px solid #fff;
    transition: all ease-in-out 0.2s;

    &:focus {
      border-color: #229534;
    }
  }

  span {
    font-size: 12px;
    color: #a63232;
    margin-top: 5px;
  }

  .submit-btn {
    position: relative;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: -1;
    }

    .disabled {
      z-index: 1;
    }
  }

  button {
    width: 100%;
    height: 60px;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #fff;
    background: transparent;
    border: 3px solid #ffffff;
    margin-top: 40px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    &:hover,
    &:focus {
      background: #229534;
      border-color: #229534;
    }
  }
`;

const heartBeat = keyframes`
  0%, 100%, 40%, 60% {
    transform: scale(1);
  }

  20%, 80% {
    transform: scale(1.3);
  }
`;

export const Ade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 65px;

  a {
    display: flex;
    align-items: center;

    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #000;

    img {
      height: 10px;
      animation-name: ${heartBeat};
      animation-iteration-count: infinite;
      animation-duration: 2s;
      margin: 0px 5px;
    }
  }
`;
